import React, { useEffect, useRef, useState } from "react";
import { Button, FileButton, Flex, Group, Text, Tooltip } from "@mantine/core";
import { IconRestore } from "@tabler/icons-react";
import { FileToDataUrl } from "shared/utils/file";

interface ImageUploaderProps {
  file?: File | null;
  fileUrl?: string | null;
  setFile?: (file: File | null) => void;
  setFileUrl?: (url: string | null) => void; // Updated type
}

const ImageUploader: React.FC<ImageUploaderProps> = ({
  file,
  setFile,
  setFileUrl,
  fileUrl,
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [imageSizeFlag, setImageSizeFlag] = useState(false);
  const acceptFormat = "image/png, image/jpeg, image/jpg";
  const ImageRef = useRef<(() => void) | null>(null);

  const clearImage = () => {
    setFileUrl?.(null); // Optional chaining ensures safe invocation
    setFile?.(null); // Add safety check for setFile
    ImageRef.current?.();
    setImageSizeFlag(false);
  };

  const handleReset = () => {
    if (inputRef.current) {
      inputRef.current.value = "";
    }
  };

  const isValidFileType = (
    file: File | null,
    allowedTypes: string
  ): boolean => {
    if (!file) return false;
    const fileExtension = file.name.split(".").pop()?.toLowerCase();
    const allowedExtensions = allowedTypes
      .split(",")
      .map((type) => type.split("/").pop()?.toLowerCase());
    return allowedExtensions.includes(fileExtension || "");
  };

  useEffect(() => {
    if (file && setFileUrl) {
      FileToDataUrl(file).then((url) => {
        setFileUrl(url);
      });
    }
  }, [file, setFileUrl]);

  return (
    <>
      <Group justify="center" mt={8}>
        <Flex direction={"column"}>
          {imageSizeFlag && (
            <Text size="xs" c="red" ta="center">
              Image size should be less than 10 MB
              <br /> please choose another
            </Text>
          )}
          <Flex justify="center" mt={8}>
            <FileButton
              onChange={(val) => {
                if (!val) {
                  alert("No file selected");
                  return;
                }

                if (isValidFileType(val, acceptFormat)) {
                  const isFileSizeInLimit = val.size / (1024 * 1024);
                  if (isFileSizeInLimit > 10) {
                    setImageSizeFlag?.(true);
                  } else {
                    setImageSizeFlag?.(false);
                    setFile?.(val);
                  }
                } else {
                  alert("Invalid file type. Please select a valid image.");
                  handleReset();
                }
              }}
              accept={acceptFormat}
              resetRef={ImageRef}
            >
              {(props) => (
                <Button disabled={!!fileUrl} {...props}>
                  Upload
                </Button>
              )}
            </FileButton>
            <Button ml={8} disabled={!fileUrl} onClick={clearImage}>
              <Tooltip label="Reset Image">
                <IconRestore />
              </Tooltip>
            </Button>
          </Flex>
        </Flex>
      </Group>
    </>
  );
};

export default ImageUploader;
