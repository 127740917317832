import { APIErrorResponse } from "shared/model/api.model";
import handleTokenExpiry from "./handleTokenExpiry";
import { Storage } from "../Storage";
import toast from "react-hot-toast";

/**
 * Handles the response from an API request and returns the parsed JSON data if the response is successful.
 * If the response is not successful, throws an error with the error message from the response body.
 *
 * @param response - The response object from the API request.
 * @returns A Promise that resolves to the parsed JSON data from the response.
 * @throws An error with the error message from the response body if the response is not successful.
 * @template T - The type of the data expected in the response body.
 */
const handleResponse = async <T = any>(
  response: Response,
  type: "GET" | "POST" | "PUT" | "PATCH" | "DELETE",
  url: string,
  data?: any
) => {
  if (response.ok) {
    return response.json() as Promise<T>;
  } else {
    const status = response.status;
    if (status === 401) {
      const refreshToken = await Storage.get(Storage.KEYS.REFRESH_TOKEN);
      if (refreshToken) {
        return handleTokenExpiry<T>(type, url, data);
      }
    } else if (status === 413) {
      toast.error("File size should be less than 100 MB");
    }
    const body: APIErrorResponse = await response.json();
    console.log("HTTP ERROR: ", body);
    throw new Error(body?.errors?.[0]?.message);
  }
};

export default handleResponse;
