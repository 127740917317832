import { useMemo, useState, useEffect } from "react";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import {
  ActionIcon,
  Button,
  Flex,
  Text,
  Title,
  Tooltip,
  Center,
  Modal,
  Select,
  TextInput,
  NumberInput,
  Box,
  Image,
  Loader,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import moment from "moment";
import { toast } from "react-hot-toast";
import { ModalsProvider, modals } from "@mantine/modals";
import { IconEdit, IconTrash, IconPlus } from "@tabler/icons-react";
import { useDisclosure } from "@mantine/hooks";
import {
  ServicesTabContextProvider,
  useServicesTabContext,
} from "./ServicesTab.context";
import { useCenterContext } from "../../Center.context";
import { REACT_TABLE_STYLE_PROP } from "shared/Constants/general.const";
import { useClubsContext } from "shared/ContextProviders/ClubsContextProvider/ClubsContextProvider";

const ServiceTabCointainer = () => {
  const { harbourData, setIsButtonDisabled } = useCenterContext();
  const { selectedClub } = useClubsContext();

  const {
    servicesData,
    handleSave,
    services,
    serviceDetailsEdit,
    deleteServicesList,
    setHarborId,
    loading,
    setLoading,
    fetchServicesList,
  } = useServicesTabContext();

  const mappedServices = services?.map((service) => {
    return {
      value: service.serviceCategoryId,
      label: service.serviceCategoryName,
    };
  });

  useEffect(() => {
    setHarborId(harbourData?.harborId);
  }, [harbourData]);

  const isServicesDataEmpty =
    !harbourData?.harborId || harbourData?.harborId.length === 0;

  const [opened, { open, close }] = useDisclosure(false);

  const [validationErrors, setValidationErrors] = useState({});

  const toggleStateData = [
    { value: "true", label: "Enabled" },
    { value: "false", label: "Disabled" },
  ];

  const categoryColors = {
    Captain: "#007aff",
    Catering: "#4dbb66",
    "Water Toys": "#db2809",
    Refueling: "#1c969c",
    Cleaning: "#684365",
    Docking: "#5f6905",
    "Extra Order": "#8b2c7d",
    "Other Services": "#f9a9ed",
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "createdAt",
        header: "Date Added",
        enableEditing: false,
        size: 100,

        Cell: ({ row }) => moment(row.original.startDate).format("DD/MM/YYYY"),
      },
      {
        accessorKey: "icon",
        size: 80,
        header: "Icon",
        enableEditing: false,

        Cell: ({ row }) => {
          const icon = row.original.icon;
          return icon ? (
            <Center>
              {" "}
              <Image
                style={{ width: "50%", height: "50%", objectFit: "contain" }}
                src={`${row.original.icon}`}
                alt="Icon"
              />
            </Center>
          ) : null;
        },
      },
      {
        accessorKey: "serviceName",
        header: "Add On Service",
        size: 100,

        mantineEditTextInputProps: {
          error: validationErrors.serviceName,
          required: true,
          onChange: (event) => {
            const value = event.target.value;
            if (!value) {
              setValidationErrors((prev) => ({
                ...prev,
                serviceName: "Service Name is required",
              }));
            } else {
              delete validationErrors.serviceName;
              setValidationErrors({ ...validationErrors });
            }
          },
        },
      },
      {
        accessorKey: "harborAvailableQuantity",
        size: 60,
        header: "Quantity",

        mantineEditTextInputProps: {
          error: validationErrors.harborAvailableQuantity,
          required: true,
          type: "number",
          onChange: (event) => {
            const value = event.target.value;
            if (!value) {
              setValidationErrors((prev) => ({
                ...prev,
                harborAvailableQuantity: "Quantity is required",
              }));
            } else if (value < 1 || value > 1000) {
              setValidationErrors({
                ...validationErrors,
                harborAvailableQuantity:
                  "Quantity must be a number between 1 and 1000",
              });
            } else if (value.includes(".")) {
              setValidationErrors({
                ...validationErrors,
                harborAvailableQuantity: "Decimal values are not allowed.",
              });
            } else {
              delete validationErrors.harborAvailableQuantity;
              setValidationErrors({ ...validationErrors });
            }
          },
        },
      },
      {
        accessorKey: "limitPerOrder",
        size: 120,
        header: "Limits per order",

        mantineEditTextInputProps: {
          error: validationErrors.limitPerOrder,
          required: true,
          type: "number",
          onChange: (event) => {
            const value = event.target.value;
            if (!value) {
              setValidationErrors((prev) => ({
                ...prev,
                limitPerOrder: "Limits is required",
              }));
            } else if (value < 1 || value > 100) {
              setValidationErrors({
                ...validationErrors,
                limitPerOrder: "Limits must be a number between 1 and 100",
              });
            } else if (value.includes(".")) {
              setValidationErrors({
                ...validationErrors,
                limitPerOrder: "Decimal values are not allowed.",
              });
            } else {
              delete validationErrors.limitPerOrder;
              setValidationErrors({ ...validationErrors });
            }
          },
        },
      },
      {
        accessorKey: "vatPercent",
        size: 120,
        header: "VAT %",

        mantineEditTextInputProps: {
          error: validationErrors.vatPercent,
          required: true,
          type: "number",
          onChange: (event) => {
            const value = event.target.value;
            if (!value) {
              setValidationErrors((prev) => ({
                ...prev,
                vatPercent: "VAT % is required",
              }));
            } else if (value < 1 || value > 100) {
              setValidationErrors({
                ...validationErrors,
                vatPercent: "VAT % must be a number between 1 and 100",
              });
            } else if (value.includes(".")) {
              setValidationErrors({
                ...validationErrors,
                vatPercent: "Decimal values are not allowed.",
              });
            } else {
              delete validationErrors.vatPercent;
              setValidationErrors({ ...validationErrors });
            }
          },
        },
      },

      {
        accessorKey: "price",
        size: 120,
        header: `Price (${selectedClub?.currencySymbol})`,
        mantineEditTextInputProps: {
          error: validationErrors.price,
          required: true,
          type: "number",
          onChange: (event) => {
            const value = event.target.value;
            if (!value) {
              setValidationErrors((prev) => ({
                ...prev,
                price: "Price is required",
              }));
            } else if (value < 1) {
              setValidationErrors({
                ...validationErrors,
                price: "Price must be a number above 1",
              });
            } else {
              delete validationErrors.price;
              setValidationErrors({ ...validationErrors });
            }
          },
        },
      },
      {
        accessorKey: "serviceCategoryId",
        size: 80,
        header: "Category",
        editVariant: "select",
        mantineEditSelectProps: {
          data: mappedServices,
          onChange: (value, event) => {
            delete validationErrors.serviceCategoryName;
            setValidationErrors({ ...validationErrors });
          },
        },
        Cell: ({ row }) => (
          <div
            style={{
              background:
                categoryColors[row.original?.serviceCategoryName] ||
                "transparent",
              color: "#ffffff",
              padding: "8px",
              borderRadius: "24px",
            }}
          >
            {row.original.serviceCategoryName}
          </div>
        ),
        mantineEditTextInputProps: {
          error: validationErrors.serviceCategoryName,
        },
      },
      {
        accessorKey: "status",
        header: "Enable/Disable",
        editVariant: "select",
        size: 100,

        mantineEditSelectProps: {
          data: toggleStateData,
          error: validationErrors?.state,
        },
        Cell: ({ row }) => (
          <div
            style={{
              color: row.original?.status === "true" ? "green" : "red",
              fontWeight: "bold",
            }}
          >
            {row.original?.status === "true" ? "Enabled" : "Disabled"}
          </div>
        ),
      },
    ],
    [validationErrors, mappedServices]
  );
  const [isAddNewButtonDisabled, setAddNewButtonDisabled] = useState(false);

  const disableAddNewButton = (isDisabled) => {
    setAddNewButtonDisabled(isDisabled);
  };

  const saveServiceDetails = async (row, values, serviceId) => {
    setLoading(true);
    const newServiceDetails = {
      serviceId: serviceId,
      harborId: harbourData?.harborId,
      serviceCategoryId: values.serviceCategoryId,
      serviceName: values.serviceName,
      vatPercent: values.vatPercent,
      price: values.price,
      harborAvailableQuantity: values.harborAvailableQuantity,
      status: values.status,
      currency: "Є",
      limitPerOrder: values.limitPerOrder,
    };

    serviceDetailsEdit(newServiceDetails, serviceId)
      .then((data) => {
        setLoading(false);
        toast.success("Service details updated successfully!");
        fetchServicesList(harbourData?.harborId);
        setIsButtonDisabled(false);
        disableAddNewButton(false);
        table.setEditingRow(null);
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.message || "Update failed. Please try again.");
      });
  };

  const handleSaveRow = async ({ row, values, table }) => {
    if (
      Object.keys(validationErrors).length ||
      Object.values(values).some((value) => !value)
    ) {
      toast.error("Please enter valid fields and try saving again!");
    } else {
      const serviceId = row.original.serviceId;
      await saveServiceDetails(row, values, serviceId);
    }
  };

  //DELETE action
  const openDeleteConfirmModal = (row) =>
    modals.openConfirmModal({
      title: "Are you sure you want to delete?",
      children: <Text>This action cannot be undone.</Text>,
      labels: { confirm: "Delete", cancel: "Cancel" },
      confirmProps: { color: "red" },
      onConfirm: () => {
        handleDeleteRow(row.original.serviceId);
      },
    });

  //EDIT action
  const handleEditClick = (row, table) => {
    table.setEditingRow(row);
    setIsButtonDisabled(true);
    disableAddNewButton(true);
  };

  const table = useMantineReactTable({
    columns,
    enableFullScreenToggle: false,
    enableTopToolbar: true,
    enableBottomToolbar: true,
    data: servicesData,
    onEditingRowSave: handleSaveRow,
    editDisplayMode: "row",
    enableEditing: true,
    initialState: { density: "xs" },
    getRowId: (row) => row.id,
    mantinePaperProps: ({ table }) => ({
      style: REACT_TABLE_STYLE_PROP,
    }),

    onEditingRowCancel: () => {
      setValidationErrors({});
      setIsButtonDisabled(false);
      disableAddNewButton(false);
    },

    renderRowActions: ({ row, table }) => (
      <Flex gap="md" justify="center">
        <Tooltip label="Edit">
          <ActionIcon
            color="#ffffff"
            onClick={() => handleEditClick(row, table)}
          >
            <IconEdit
              height={28}
              width={28}
              color="black"
              style={{ border: "1px solid #ffffff", borderRadius: "12px" }}
            />
          </ActionIcon>
        </Tooltip>
        <Tooltip label="Delete">
          <ActionIcon
            color="#ffffff"
            onClick={() => openDeleteConfirmModal(row)}
          >
            <IconTrash color="red" />
          </ActionIcon>
        </Tooltip>
      </Flex>
    ),
  });

  const handleDeleteRow = (serviceId) => {
    deleteServicesList(serviceId);
  };

  const resetStateValues = () => {
    form.reset({
      service: "",
      price: 0,
      quantity: 0,
      limit: 0,
      serviceCategory: "",
      status: "",
      vat: 0,
    });

    form.clearErrors();
  };

  const form = useForm({
    initialValues: {
      service: "",
      price: 0,
      quantity: 0,
      limit: 0,
      vat: 0,
      serviceCategory: "",
      status: "",
    },

    validate: {
      service: (value) => (value.length < 1 ? "Name must reqired." : null),

      price: (value) => (value < 1 ? "Price must be a above 0." : null),
      quantity: (value) => {
        if (isNaN(value) || value < 1 || value > 1000) {
          return "Quantity must be a number between 1 and 1000.";
        } else {
          return null;
        }
      },
      limit: (value) =>
        value < 1 || value > 100
          ? "Limit must be a number between 1 and 100."
          : null,
      serviceCategory: (value) =>
        !value ? "Please select a service category" : null,
      status: (value) => (!value ? "Please select a Status" : null),
      vat: (value) =>
        value < 1 || value > 100
          ? "VAT % must be a number between 1 and 100."
          : null,
    },
  });

  const postData = {
    harborId: harbourData?.harborId,
    serviceCategoryId: form.values.serviceCategory,
    serviceName: form.values.service,
    price: form.values.price,
    harborAvailableQuantity: form.values.quantity,
    status: form.values.status,
    vatPercent: String(form.values.vat),
    currency: selectedClub?.currencySymbol,
    limitPerOrder: form.values.limit,
  };

  return (
    <>
      <Flex direction="row" align="center" mt={10}>
        <Title order={3} mt={8} mb={8}>
          Services- {harbourData && harbourData.harborName}
        </Title>
        <Button
          onClick={open}
          leftSection={<IconPlus size={14} />}
          variant="outline"
          color="#000000"
          ml={8}
          disabled={isServicesDataEmpty || isAddNewButtonDisabled}
        >
          Add New
        </Button>
      </Flex>
      <Modal
        opened={opened}
        onClose={() => {
          resetStateValues();
          close();
        }}
        centered
        title="New Add on Service"
      >
        {loading ? (
          <Flex justify="center" align="center" h={"60vh"}>
            <Loader />
          </Flex>
        ) : (
          <Box
            component="form"
            onSubmit={form.onSubmit(() => {
              if (form.isValid()) {
                setLoading(true);
                handleSave(postData)
                  .then(() => {
                    fetchServicesList(harbourData?.harborId);
                    toast.success("Service added successfully!");
                    setLoading(false);
                    close();
                    resetStateValues();
                  })
                  .catch((error) => {
                    toast.error(
                      error.message || "Service add failed. Please try again."
                    );
                    setLoading(false);
                  });
              }
            })}
          >
            <Flex direction="column" rowGap={12}>
              <Select
                withAsterisk
                label="Service Category"
                placeholder="Service Category"
                clearable
                data={mappedServices}
                onChange={(selectedServiceCategoryId) => {
                  form.values(selectedServiceCategoryId);
                }}
                {...form.getInputProps("serviceCategory")}
              />
              <TextInput
                withAsterisk
                label="Add On Service Name"
                mt={4}
                placeholder="Add On Services"
                onChange={(e) => {
                  form.change("service", e.target.value);
                }}
                {...form.getInputProps("service")}
              />
              <NumberInput
                withAsterisk
                label={`Price (${selectedClub?.currencySymbol})`}
                placeholder="Price"
                hideControls
                decimalScale={2}
                mt={4}
                {...form.getInputProps("price")}
              />
              <NumberInput
                withAsterisk
                description="Quantity between 1 and 1000."
                label="Quantity"
                placeholder="Quantity"
                hideControls
                allowDecimal={false}
                mt={4}
                {...form.getInputProps("quantity")}
              />
              <NumberInput
                withAsterisk
                description="Limit between 1 and 100."
                label="Limits per order"
                placeholder="Limits per order"
                hideControls
                allowDecimal={false}
                mt={4}
                {...form.getInputProps("limit")}
              />
              <NumberInput
                withAsterisk
                description="VAT % between 1 and 100."
                label="VAT %"
                allowDecimal={false}
                placeholder="VAT %"
                hideControls
                mt={4}
                {...form.getInputProps("vat")}
              />
              <Select
                withAsterisk
                label="Enabled/Disabled"
                clearable
                placeholder="Select"
                mt={4}
                data={[
                  { label: "Enabled", value: "true" },
                  { label: "Disabled", value: "false" },
                ]}
                {...form.getInputProps("status")}
              />
            </Flex>
            <Flex justify="space-between" mt={12}>
              <Button
                onClick={() => {
                  resetStateValues();
                  close();
                }}
              >
                Cancel
              </Button>
              <Button type="submit">Save</Button>
            </Flex>
          </Box>
        )}
      </Modal>
      {loading ? (
        <Flex justify={"center"} align={"center"} h={"70vh"}>
          <Loader />
        </Flex>
      ) : (
        <MantineReactTable table={table} rowKey={(row) => row.serviceId} />
      )}
    </>
  );
};

const ServicesTab = () => {
  return (
    <ServicesTabContextProvider>
      <ModalsProvider>
        <ServiceTabCointainer />
      </ModalsProvider>
    </ServicesTabContextProvider>
  );
};

export default ServicesTab;
