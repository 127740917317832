import { createContext, useContext, useState, useEffect } from "react";
import { API_URLS } from "shared/Constants/api.const";
import http from "shared/utils/http";
import { toast } from "react-hot-toast";

const ServicesTabContext = createContext({
  harbourData: [],
  servicesData: [],
  services: [],
  handleSave: () => {},
  serviceDetailsEdit: {},
  deleteServicesList: () => {},
  loading: true,
  fetchServicesList: () => {},
  setLoading: () => {},
});

export const ServicesTabContextProvider = ({ children }) => {
  const [servicesData, setServicesData] = useState([]);
  const [harborId, setHarborId] = useState("");
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);

  // GET Api call
  const fetchServicesList = async (harborId) => {
    //API for service Data
    if (harborId) {
      await http
        .get(`${API_URLS.GET_SERVICES_LIST}?harborId=${harborId}`)
        .then((data) => {
          setServicesData(data.services);
        })
        .catch((error) => {});

      setLoading(false);
    }
  };

  const handleSave = async (data) => {
    await http.post(API_URLS.ADD_SERVICES, data);
  };

  const Services = async () => {
    const response = await http
      .get(`${API_URLS.GET_SERVICE_CATEGORIES_IDS}`)
      .then((data) => data)
      .catch((error) => {});
    setServices(response?.ServiceCategories || []);
  };

  // Create a function for PUT membershipDetailsEdit
  const serviceDetailsEdit = async (newServiceDetails, serviceId) => {
    await http.put(`${API_URLS.EDIT_SERVICES}/${serviceId}`, newServiceDetails);
  };

  const deleteServicesList = async (serviceId) => {
    setLoading(true);
    await http
      .destroy(`${API_URLS.DELETE_SERVICES}/${serviceId}`)
      .then((response) => {
        if (response) {
          toast.success("Delete successfully!");
          fetchServicesList(harborId);
          setLoading(false);
        } else {
          console.error(`Failed to delete service with ID ${serviceId}`);
        }
      })
      .catch((error) => {
        toast.error(error.message || "Delete failed. Please try again.");
      });
  };

  useEffect(() => {
    Services();
  }, []);

  useEffect(() => {
    fetchServicesList(harborId);
  }, [harborId]);

  return (
    <ServicesTabContext.Provider
      value={{
        servicesData,
        handleSave,
        fetchServicesList,
        services,
        serviceDetailsEdit,
        deleteServicesList,
        setHarborId,
        loading,
        fetchServicesList,
        setLoading,
      }}
    >
      {children}
    </ServicesTabContext.Provider>
  );
};

export const useServicesTabContext = () => useContext(ServicesTabContext);
