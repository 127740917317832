import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDisclosure } from "@mantine/hooks";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { Flex, Title, Button, Modal, Loader, Text } from "@mantine/core";
import { IconPlus } from "@tabler/icons-react";
import {
  ClubManagementnContextProvider,
  useClubManagementContext,
} from "./ClubManagement.context";
import AddClubs from "../ClubManagement/Components/AddClubs/AddClubs";
import ViewClubs from "./Components/ViewClubs/ViewClubs";
import { getWriteClubPermissions } from "shared/utils/UserPermission";
import ViewUser from "./Components/ViewUser/ViewUser";
import { useUserContext } from "shared/ContextProviders/UserContextProvider/UserContextProvider";
import toast from "react-hot-toast";
import {
  REACT_TABLE_STYLE_PROP,
  USER_ROLE,
} from "shared/Constants/general.const";

const ClubManagementContainer = () => {
  const {
    userData,
    loading,
    fetchClubDetails,
    fetchUserDetails,
    userDetailsData,
    deactivateClub,
    activateClub,
  } = useClubManagementContext();
  const navigate = useNavigate();
  const [clubManager, setClubManager] = useState("");
  const [clubId, setClubId] = useState({});
  const [opened, { open, close }] = useDisclosure(false);
  const [deactivateOpened, { open: deactivateOpen, close: deactivateClose }] =
    useDisclosure(false);
  const [
    clubDetailsOpened,
    { open: clubDetailsOpen, close: clubDetailsclose },
  ] = useDisclosure(false);
  const { userPermissions } = useUserContext();

  const [
    userDetailsOpened,
    { open: userDetailsOpen, close: userDetailsClose },
  ] = useDisclosure(false);

  const handleDeactivateClub = () => {
    if (clubId.clubActive === true) {
      deactivateClub(clubId.id)
        .then(() => {
          toast.success("Club deactivated successfully!");
          deactivateClose();
          setClubId({});
        })
        .catch(() => {
          toast.error("Something went wrong");
        });
    } else {
      activateClub(clubId.id)
        .then(() => {
          toast.success("Club activated successfully!");
          deactivateClose();
          setClubId({});
        })
        .catch(() => {
          toast.error("Something went wrong");
        });
    }
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "Details",
        header: "Details",
        Cell: ({ row }) => (
          <Button
            w={"100%"}
            onClick={() => {
              const clubId = row.original.id;
              if (row.original.clubManager === null) {
                navigate("/administration/user-management");
              } else {
                const clubManager = row.original.clubManager;
                setClubManager(clubManager);
                fetchClubDetails(clubId);
                clubDetailsOpen();
              }
            }}
          >
            {row.original.clubManager === null ? "Add ClubManager" : "View"}
          </Button>
        ),
      },
      {
        accessorKey: "Deactive",
        header: "Active/Inactive",
        Cell: ({ row }) => (
          <Button
            w={"100%"}
            onClick={() => {
              deactivateOpen();
              setClubId(row.original);
            }}
            disabled={
              !(
                userPermissions.roleName ===
                USER_ROLE.MOXSEA_Administrator.value
              )
            }
          >
            {row.original.clubActive ? "Deactivate" : "Activate"}
          </Button>
        ),
      },
      {
        accessorKey: "clubName",
        header: "Club Name",
      },
      {
        accessorKey: "clubManager",
        header: "Club Manager",
        Cell: ({ row }) => (
          <div
            style={{
              fontWeight: "bold",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => {
              const clubManagerId = row.original.clubManagerId;
              fetchUserDetails(clubManagerId);
              userDetailsOpen();
            }}
          >
            {row.original.clubManager}
          </div>
        ),
      },
      {
        accessorKey: "activeMemberships",
        header: "Memberships/Clients",
      },
      {
        accessorKey: "annualRunRate",
        header: "Annual Run Rate",
        Cell: ({ row }) => (
          <div>{Number(row.original?.annualRunRate).toFixed(2)}</div>
        ),
      },
      {
        accessorKey: "averageBookings",
        header: "Average Bookings",
        Cell: ({ row }) => (
          <div>{Number(row.original?.averageBookings).toFixed(2)}</div>
        ),
      },
    ],
    []
  );

  const table = useMantineReactTable({
    columns,
    data: userData,
    createDisplayMode: "row",
    editDisplayMode: "row",
    enableEditing: false,
    enableTopToolbar: true,
    initialState: { density: "xs" },
    mantinePaperProps: ({ table }) => ({
      style: REACT_TABLE_STYLE_PROP,
    }),
    getRowId: (row) => row.id,
  });

  const AddNewButtonDisable = getWriteClubPermissions(
    "Club Management",
    userPermissions
  );
  return (
    <Flex direction="column">
      {loading ? (
        <Flex justify="center" align="center" h="70vh">
          <Loader />
        </Flex>
      ) : (
        <>
          <Flex mb={8} columnGap={12}>
            <Title order={3}>Administration - Club Management</Title>
            <Button
              leftSection={<IconPlus size={20} />}
              variant="outline"
              color="#000000"
              disabled={!AddNewButtonDisable}
              onClick={open}
            >
              Add New
            </Button>
          </Flex>
          <Modal
            opened={opened}
            onClose={close}
            title="Add Club Details"
            size="lg"
          >
            <AddClubs close={close} />
          </Modal>

          <MantineReactTable table={table} />
          <>
            <Modal
              opened={clubDetailsOpened}
              onClose={clubDetailsclose}
              title="Club Details"
              size="lg"
            >
              <ViewClubs
                closeHandler={clubDetailsclose}
                clubManager={clubManager}
              />
            </Modal>
          </>
          <>
            <Modal
              opened={userDetailsOpened}
              onClose={userDetailsClose}
              title="View User Details"
              size="lg"
            >
              <ViewUser
                userDetailsData={userDetailsData}
                userDetailsClose={userDetailsClose}
              />
            </Modal>
          </>
          <>
            <Modal
              opened={deactivateOpened}
              onClose={deactivateClose}
              withCloseButton={false}
              size="sm"
            >
              <Flex
                justify="center"
                align="center"
                direction="column"
                w={"100%"}
              >
                <Title order={5}>
                  {clubId.clubActive
                    ? "Are you sure you want to Deactivate?"
                    : "Are you sure you want to Activate?"}
                </Title>
                <Text>This action cannot be undone</Text>
                <Flex w={"100%"} justify={"space-around"} mt={20}>
                  <Button
                    onClick={() => {
                      deactivateClose();
                      setClubId({});
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    color={clubId.clubActive ? "red" : "#248b5b"}
                    onClick={() => {
                      handleDeactivateClub();
                    }}
                  >
                    {clubId.clubActive ? "Deactivate" : "Activate"}
                  </Button>
                </Flex>
              </Flex>
            </Modal>
          </>
        </>
      )}
    </Flex>
  );
};

const ClubManagement = () => {
  return (
    <ClubManagementnContextProvider>
      <ClubManagementContainer />
    </ClubManagementnContextProvider>
  );
};

export default ClubManagement;
