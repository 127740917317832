export const EMAIL_VALIDATION_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const appUserId = "13c30678-246e-45d2-9da1-3885ab72ca3b";

export const ADMINISTRATOR = {
  CLUB_MANAGEMENT: "Club Management",
  USER_MANAGEMENT: "User Management",
};

export const COLORS = {
  PRIMARY_BLUE: "#0090ff",
  G1: "#242424",
  G2: "#efefef",
  G3: "#949494",
  G4: "#000000",
  G5: "#222222",
  G6: "#DDDDDD",
};

export const FUEL_TYPE = ["Litre", "Gallon"];

export const BOAT_STATUS = ["Activated", "Deactivated"];

export const ENGINE_TYPE = ["Outboarder / Inboarder", "Electric", "Hybrid"];

export const USER_TYPE = {
  CLIENT_USERS: "CLIENT_USERS",
  ADMIN_USERS: "ADMIN_USERS",
};

export const VAT_TYPE = [
  {
    countryName: "Andorra",
    item: [
      {
        country: "Andorra",
        vatType: "ad_nrt",
        refNumber: "A-123456-Z",
      },
    ],
  },
  {
    countryName: "Argentina",
    item: [
      {
        country: "Argentina",
        vatType: "ar_cuit",
        refNumber: "12-3456789-01",
      },
    ],
  },
  {
    countryName: "Australia",
    item: [
      {
        country: "Australia",
        vatType: "au_abn",
        refNumber: "12345678912",
      },
      {
        country: "Australia",
        vatType: "au_arn",
        refNumber: "123456789123",
      },
    ],
  },
  {
    countryName: "Austria",
    item: [
      {
        country: "Austria",
        vatType: "eu_vat",
        refNumber: "ATU12345678",
      },
    ],
  },
  {
    countryName: "Bahrain",
    item: [
      {
        country: "Bahrain",
        vatType: "bh_vat",
        refNumber: "123456789012345",
      },
    ],
  },
  {
    countryName: "Belarus",
    item: [
      {
        country: "Belarus",
        vatType: "by_tin",
        refNumber: "123456789",
      },
    ],
  },
  {
    countryName: "Belgium",
    item: [
      {
        country: "Belgium",
        vatType: "eu_vat",
        refNumber: "BE0123456789",
      },
    ],
  },
  {
    countryName: "Bolivia",
    item: [
      {
        country: "Bolivia",
        vatType: "bo_tin",
        refNumber: "123456789",
      },
    ],
  },
  {
    countryName: "Brazil",
    item: [
      {
        country: "Brazil",
        vatType: "br_cnpj",
        refNumber: "01.234.456/5432-10",
      },
      {
        country: "Brazil",
        vatType: "br_cpf",
        refNumber: "123.456.789-87",
      },
    ],
  },
  {
    countryName: "Bulgaria",
    item: [
      {
        country: "Bulgaria",
        vatType: "bg_uic",
        refNumber: "123456789",
      },
      {
        country: "Bulgaria",
        vatType: "eu_vat",
        refNumber: "BG0123456789",
      },
    ],
  },
  {
    countryName: "Canada",
    item: [
      {
        country: "Canada",
        vatType: "ca_bn",
        refNumber: "123456789",
      },
      {
        country: "Canada",
        vatType: "ca_gst_hst",
        refNumber: "123456789RT0002",
      },
      {
        country: "Canada",
        vatType: "ca_pst_bc",
        refNumber: "PST-1234-5678",
      },
      {
        country: "Canada",
        vatType: "ca_pst_mb",
        refNumber: "123456-7",
      },
      {
        country: "Canada",
        vatType: "ca_pst_sk",
        refNumber: "1234567",
      },
      {
        country: "Canada",
        vatType: "ca_qst",
        refNumber: "1234567890TQ1234",
      },
    ],
  },
  {
    countryName: "Chile",
    item: [
      {
        country: "Chile",
        vatType: "cl_tin",
        refNumber: "12.345.678-K",
      },
    ],
  },
  {
    countryName: "China",
    item: [
      {
        country: "China",
        vatType: "cn_tin",
        refNumber: "123456789012345678",
      },
    ],
  },
  {
    countryName: "Colombia",
    item: [
      {
        country: "Colombia",
        vatType: "co_nit",
        refNumber: "123.456.789-0",
      },
    ],
  },
  {
    countryName: "Costa Rica",
    item: [
      {
        country: "Costa Rica",
        vatType: "cr_tin",
        refNumber: "1-234-567890",
      },
    ],
  },
  {
    countryName: "Croatia",
    item: [
      {
        country: "Croatia",
        vatType: "eu_vat",
        refNumber: "HR12345678912",
      },
      {
        country: "Croatia",
        vatType: "hr_oib",
        refNumber: "12345678901",
      },
    ],
  },
  {
    countryName: "Cyprus",
    item: [
      {
        country: "Cyprus",
        vatType: "eu_vat",
        refNumber: "CY12345678Z",
      },
    ],
  },
  {
    countryName: "Czech Republic",
    item: [
      {
        country: "Czech Republic",
        vatType: "eu_vat",
        refNumber: "CZ1234567890",
      },
    ],
  },
  {
    countryName: "Denmark",
    item: [
      {
        country: "Denmark",
        vatType: "eu_vat",
        refNumber: "DK12345678",
      },
    ],
  },
  {
    countryName: "Dominican Republic",
    item: [
      {
        country: "Dominican Republic",
        vatType: "do_rcn",
        refNumber: "123-4567890-1",
      },
    ],
  },
  {
    countryName: "Ecuador",
    item: [
      {
        country: "Ecuador",
        vatType: "ec_ruc",
        refNumber: "1234567890001",
      },
    ],
  },
  {
    countryName: "Egypt",
    item: [
      {
        country: "Egypt",
        vatType: "eg_tin",
        refNumber: "123456789",
      },
    ],
  },
  {
    countryName: "El Salvador",
    item: [
      {
        country: "El Salvador",
        vatType: "sv_nit",
        refNumber: "1234-567890-123-4",
      },
    ],
  },
  {
    countryName: "Estonia",
    item: [
      {
        country: "Estonia",
        vatType: "eu_vat",
        refNumber: "EE123456789",
      },
    ],
  },
  {
    countryName: "EU",
    item: [
      {
        country: "EU",
        vatType: "eu_oss_vat",
        refNumber: "EU123456789",
      },
    ],
  },
  {
    countryName: "Finland",
    item: [
      {
        country: "Finland",
        vatType: "eu_vat",
        refNumber: "FI12345678",
      },
    ],
  },
  {
    countryName: "France",
    item: [
      {
        country: "France",
        vatType: "eu_vat",
        refNumber: "FRAB123456789",
      },
    ],
  },
  {
    countryName: "Georgia",
    item: [
      {
        country: "Georgia",
        vatType: "ge_vat",
        refNumber: "123456789",
      },
    ],
  },
  {
    countryName: "Germany",
    item: [
      {
        country: "Germany",
        vatType: "de_stn",
        refNumber: "1234567890",
      },
      {
        country: "Germany",
        vatType: "eu_vat",
        refNumber: "DE123456789",
      },
    ],
  },
  {
    countryName: "Greece",
    item: [
      {
        country: "Greece",
        vatType: "eu_vat",
        refNumber: "EL123456789",
      },
    ],
  },
  {
    countryName: "Hong Kong",
    item: [
      {
        country: "Hong Kong",
        vatType: "hk_br",
        refNumber: "12345678",
      },
    ],
  },
  {
    countryName: "Hungary",
    item: [
      {
        country: "Hungary",
        vatType: "eu_vat",
        refNumber: "HU12345678",
      },
      {
        country: "Hungary",
        vatType: "hu_tin",
        refNumber: "12345678-1-23",
      },
    ],
  },
  {
    countryName: "Iceland",
    item: [
      {
        country: "Iceland",
        vatType: "is_vat",
        refNumber: "123456",
      },
    ],
  },
  {
    countryName: "India",
    item: [
      {
        country: "India",
        vatType: "in_gst",
        refNumber: "12ABCDE3456FGZH",
      },
    ],
  },
  {
    countryName: "Indonesia",
    item: [
      {
        country: "Indonesia",
        vatType: "id_npwp",
        refNumber: "012.345.678.9-012.345",
      },
    ],
  },
  {
    countryName: "Ireland",
    item: [
      {
        country: "Ireland",
        vatType: "eu_vat",
        refNumber: "IE1234567AB",
      },
    ],
  },
  {
    countryName: "Israel",
    item: [
      {
        country: "Israel",
        vatType: "il_vat",
        refNumber: "000012345",
      },
    ],
  },
  {
    countryName: "Italy",
    item: [
      {
        country: "Italy",
        vatType: "eu_vat",
        refNumber: "IT12345678912",
      },
    ],
  },
  {
    countryName: "Japan",
    item: [
      {
        country: "Japan",
        vatType: "jp_cn",
        refNumber: "1234567890123",
      },
      {
        country: "Japan",
        vatType: "jp_rn",
        refNumber: "12345",
      },
      {
        country: "Japan",
        vatType: "jp_trn",
        refNumber: "T1234567891234",
      },
    ],
  },
  {
    countryName: "Kazakhstan",
    item: [
      {
        country: "Kazakhstan",
        vatType: "kz_bin",
        refNumber: "123456789012",
      },
    ],
  },
  {
    countryName: "Kenya",
    item: [
      {
        country: "Kenya",
        vatType: "ke_pin",
        refNumber: "A123456789B",
      },
    ],
  },
  {
    countryName: "Latvia",
    item: [
      {
        country: "Latvia",
        vatType: "eu_vat",
        refNumber: "LV12345678912",
      },
    ],
  },
  {
    countryName: "Liechtenstein",
    item: [
      {
        country: "Liechtenstein",
        vatType: "li_uid",
        refNumber: "CHE123456789",
      },
      {
        country: "Liechtenstein",
        vatType: "li_vat",
        refNumber: "12345",
      },
    ],
  },
  {
    countryName: "Lithuania",
    item: [
      {
        country: "Lithuania",
        vatType: "eu_vat",
        refNumber: "LT123456789123",
      },
    ],
  },
  {
    countryName: "Luxembourg",
    item: [
      {
        country: "Luxembourg",
        vatType: "eu_vat",
        refNumber: "LU12345678",
      },
    ],
  },
  {
    countryName: "Malaysia",
    item: [
      {
        country: "Malaysia",
        vatType: "my_frp",
        refNumber: "12345678",
      },
      {
        country: "Malaysia",
        vatType: "my_itn",
        refNumber: "C 1234567890",
      },
      {
        country: "Malaysia",
        vatType: "my_sst",
        refNumber: "A12-3456-78912345",
      },
    ],
  },
  {
    countryName: "Malta",
    item: [
      {
        country: "Malta",
        vatType: "eu_vat",
        refNumber: "MT12345678",
      },
    ],
  },
  {
    countryName: "Mexico",
    item: [
      {
        country: "Mexico",
        vatType: "mx_rfc",
        refNumber: "ABC123456789",
      },
    ],
  },
  {
    countryName: "Moldova",
    item: [
      {
        country: "Moldova",
        vatType: "md_vat",
        refNumber: "1234567",
      },
    ],
  },
  {
    countryName: "Morocco",
    item: [
      {
        country: "Morocco",
        vatType: "ma_vat",
        refNumber: "12345678",
      },
    ],
  },
  {
    countryName: "Netherlands",
    item: [
      {
        country: "Netherlands",
        vatType: "eu_vat",
        refNumber: "NL123456789B12",
      },
    ],
  },
  {
    countryName: "New Zealand",
    item: [
      {
        country: "New Zealand",
        vatType: "nz_gst",
        refNumber: "123-456-789",
      },
    ],
  },
  {
    countryName: "Nigeria",
    item: [
      {
        country: "Nigeria",
        vatType: "ng_tin",
        refNumber: "12345678-0001",
      },
    ],
  },
  {
    countryName: "Norway",
    item: [
      {
        country: "Norway",
        vatType: "no_vat",
        refNumber: "123456789MVA",
      },
      {
        country: "Norway",
        vatType: "no_voec",
        refNumber: "1234567",
      },
    ],
  },
  {
    countryName: "Oman",
    item: [
      {
        country: "Oman",
        vatType: "om_vat",
        refNumber: "OM1234567890",
      },
    ],
  },
  {
    countryName: "Peru",
    item: [
      {
        country: "Peru",
        vatType: "pe_ruc",
        refNumber: "12345678901",
      },
    ],
  },
  {
    countryName: "Philippines",
    item: [
      {
        country: "Philippines",
        vatType: "ph_tin",
        refNumber: "123456789012",
      },
    ],
  },
  {
    countryName: "Poland",
    item: [
      {
        country: "Poland",
        vatType: "eu_vat",
        refNumber: "PL1234567890",
      },
    ],
  },
  {
    countryName: "Portugal",
    item: [
      {
        country: "Portugal",
        vatType: "eu_vat",
        refNumber: "PT123456789",
      },
    ],
  },
  {
    countryName: "Romania",
    item: [
      {
        country: "Romania",
        vatType: "eu_vat",
        refNumber: "RO1234567891",
      },
      {
        country: "Romania",
        vatType: "ro_tin",
        refNumber: "1234567890123",
      },
    ],
  },
  {
    countryName: "Russia",
    item: [
      {
        country: "Russia",
        vatType: "ru_inn",
        refNumber: "1234567891",
      },
      {
        country: "Russia",
        vatType: "ru_kpp",
        refNumber: "123456789",
      },
    ],
  },
  {
    countryName: "Saudi Arabia",
    item: [
      {
        country: "Saudi Arabia",
        vatType: "sa_vat",
        refNumber: "123456789012345",
      },
    ],
  },
  {
    countryName: "Serbia",
    item: [
      {
        country: "Serbia",
        vatType: "rs_pib",
        refNumber: "123456789",
      },
    ],
  },
  {
    countryName: "Singapore",
    item: [
      {
        country: "Singapore",
        vatType: "sg_gst",
        refNumber: "M12345678X",
      },
      {
        country: "Singapore",
        vatType: "sg_uen",
        refNumber: "123456789F",
      },
    ],
  },
  {
    countryName: "Slovakia",
    item: [
      {
        country: "Slovakia",
        vatType: "eu_vat",
        refNumber: "SK1234567891",
      },
    ],
  },
  {
    countryName: "Slovenia",
    item: [
      {
        country: "Slovenia",
        vatType: "eu_vat",
        refNumber: "SI12345678",
      },
      {
        country: "Slovenia",
        vatType: "si_tin",
        refNumber: "12345678",
      },
    ],
  },
  {
    countryName: "South Africa",
    item: [
      {
        country: "South Africa",
        vatType: "za_vat",
        refNumber: "4123456789",
      },
    ],
  },
  {
    countryName: "South Korea",
    item: [
      {
        country: "South Korea",
        vatType: "kr_brn",
        refNumber: "123-45-67890",
      },
    ],
  },
  {
    countryName: "Spain",
    item: [
      {
        country: "Spain",
        vatType: "es_cif",
        refNumber: "A12345678",
      },
      {
        country: "Spain",
        vatType: "eu_vat",
        refNumber: "ESA1234567Z",
      },
    ],
  },
  {
    countryName: "Sweden",
    item: [
      {
        country: "Sweden",
        vatType: "eu_vat",
        refNumber: "SE123456789123",
      },
    ],
  },
  {
    countryName: "Switzerland",
    item: [
      {
        country: "Switzerland",
        vatType: "ch_uid",
        refNumber: "CHE-123.456.789 HR",
      },
      {
        country: "Switzerland",
        vatType: "ch_vat",
        refNumber: "CHE-123.456.789 MWST",
      },
    ],
  },
  {
    countryName: "Taiwan",
    item: [
      {
        country: "Taiwan",
        vatType: "tw_vat",
        refNumber: "12345678",
      },
    ],
  },
  {
    countryName: "Tanzania",
    item: [
      {
        country: "Tanzania",
        vatType: "tz_vat",
        refNumber: "12345678A",
      },
    ],
  },
  {
    countryName: "Thailand",
    item: [
      {
        country: "Thailand",
        vatType: "th_vat",
        refNumber: "1234567891234",
      },
    ],
  },
  {
    countryName: "Turkey",
    item: [
      {
        country: "Turkey",
        vatType: "tr_tin",
        refNumber: "0123456789",
      },
    ],
  },
  {
    countryName: "Ukraine",
    item: [
      {
        country: "Ukraine",
        vatType: "ua_vat",
        refNumber: "123456789",
      },
    ],
  },
  {
    countryName: "United Arab Emirates",
    item: [
      {
        country: "United Arab Emirates",
        vatType: "ae_trn",
        refNumber: "123456789012345",
      },
    ],
  },
  {
    countryName: "United Kingdom",
    item: [
      {
        country: "United Kingdom",
        vatType: "eu_vat",
        refNumber: "XI123456789",
      },
      {
        country: "United Kingdom",
        vatType: "gb_vat",
        refNumber: "GB123456789",
      },
    ],
  },
  {
    countryName: "United States",
    item: [
      {
        country: "United States",
        vatType: "us_ein",
        refNumber: "12-3456789",
      },
    ],
  },
  {
    countryName: "Uruguay",
    item: [
      {
        country: "Uruguay",
        vatType: "uy_ruc",
        refNumber: "123456789012",
      },
    ],
  },
  {
    countryName: "Uzbekistan",
    item: [
      {
        country: "Uzbekistan",
        vatType: "uz_tin",
        refNumber: "123456789",
      },
      {
        country: "Uzbekistan",
        vatType: "uz_vat",
        refNumber: "123456789012",
      },
    ],
  },
  {
    countryName: "Venezuela",
    item: [
      {
        country: "Venezuela",
        vatType: "ve_rif",
        refNumber: "A-12345678-9",
      },
    ],
  },
  {
    countryName: "Vietnam",
    item: [
      {
        country: "Vietnam",
        vatType: "vn_tin",
        refNumber: "1234567890",
      },
    ],
  },
];

// const Data = [
//   { country: "Afghanistan", currencyCode: "afn", currencySymbol: "؋" },
//   { country: "Albania", currencyCode: "all", currencySymbol: "L" },
//   { country: "Algeria", currencyCode: "dzd", currencySymbol: "دج" },
//   { country: "Andorra", currencyCode: "eur", currencySymbol: "€" },
//   { country: "Angola", currencyCode: "aoa", currencySymbol: "Kz" },
//   { country: "Antigua and Barbuda", currencyCode: "xcd", currencySymbol: "$" },
//   { country: "Argentina", currencyCode: "ars", currencySymbol: "$" },
//   { country: "Armenia", currencyCode: "amd", currencySymbol: "֏" },
//   { country: "Australia", currencyCode: "aud", currencySymbol: "$" },
//   { country: "Austria", currencyCode: "eur", currencySymbol: "€" },
//   { country: "Azerbaijan", currencyCode: "azn", currencySymbol: "₼" },
//   { country: "Bahamas", currencyCode: "bsd", currencySymbol: "$" },
//   { country: "Bahrain", currencyCode: "bhd", currencySymbol: ".د.ب" },
//   { country: "Bangladesh", currencyCode: "bdt", currencySymbol: "৳" },
//   { country: "Barbados", currencyCode: "bbd", currencySymbol: "$" },
//   { country: "Belarus", currencyCode: "byn", currencySymbol: "Br" },
//   { country: "Belgium", currencyCode: "eur", currencySymbol: "€" },
//   { country: "Belize", currencyCode: "bzd", currencySymbol: "$" },
//   { country: "Benin", currencyCode: "xof", currencySymbol: "CFA" },
//   { country: "Bhutan", currencyCode: "btn", currencySymbol: "Nu." },
//   { country: "Bolivia", currencyCode: "bob", currencySymbol: "Bs." },
//   { country: "Bosnia and Herzegovina", currencyCode: "bam", currencySymbol: "KM" },
//   { country: "Botswana", currencyCode: "bwp", currencySymbol: "P" },
//   { country: "Brazil", currencyCode: "brl", currencySymbol: "R$" },
//   { country: "Brunei", currencyCode: "bnd", currencySymbol: "$" },
//   { country: "Bulgaria", currencyCode: "bgn", currencySymbol: "лв" },
//   { country: "Burkina Faso", currencyCode: "xof", currencySymbol: "CFA" },
//   { country: "Burundi", currencyCode: "bif", currencySymbol: "FBu" },
//   { country: "Cabo Verde", currencyCode: "cve", currencySymbol: "Esc" },
//   { country: "Cambodia", currencyCode: "khr", currencySymbol: "៛" },
//   { country: "Cameroon", currencyCode: "xaf", currencySymbol: "FCFA" },
//   { country: "Canada", currencyCode: "cad", currencySymbol: "$" },
//   { country: "Central African Republic", currencyCode: "xaf", currencySymbol: "FCFA" },
//   { country: "Chad", currencyCode: "xaf", currencySymbol: "FCFA" },
//   { country: "Chile", currencyCode: "clp", currencySymbol: "$" },
//   { country: "China", currencyCode: "cny", currencySymbol: "¥" },
//   { country: "Colombia", currencyCode: "cop", currencySymbol: "$" },
//   { country: "Comoros", currencyCode: "kmf", currencySymbol: "CF" },
//   { country: "Congo (Brazzaville)", currencyCode: "xaf", currencySymbol: "FCFA" },
//   { country: "Congo (Kinshasa)", currencyCode: "cdf", currencySymbol: "FC" },
//   { country: "Costa Rica", currencyCode: "crc", currencySymbol: "₡" },
//   { country: "Croatia", currencyCode: "hrk", currencySymbol: "kn" },
//   { country: "Cuba", currencyCode: "cup", currencySymbol: "₱" },
//   { country: "Cyprus", currencyCode: "eur", currencySymbol: "€" },
//   { country: "Czech Republic", currencyCode: "czk", currencySymbol: "Kč" },
//   { country: "Denmark", currencyCode: "dkk", currencySymbol: "kr" },
//   { country: "Djibouti", currencyCode: "djf", currencySymbol: "Fdj" },
//   { country: "Dominica", currencyCode: "xcd", currencySymbol: "$" },
//   { country: "Dominican Republic", currencyCode: "dop", currencySymbol: "RD$" },
//   { country: "Ecuador", currencyCode: "usd", currencySymbol: "$" },
//   { country: "Egypt", currencyCode: "egp", currencySymbol: "£" },
//   { country: "El Salvador", currencyCode: "usd", currencySymbol: "$" },
//   { country: "Equatorial Guinea", currencyCode: "xaf", currencySymbol: "FCFA" },
//   { country: "Eritrea", currencyCode: "ern", currencySymbol: "Nfk" },
//   { country: "Estonia", currencyCode: "eur", currencySymbol: "€" },
//   { country: "Eswatini", currencyCode: "szl", currencySymbol: "E" },
//   { country: "Ethiopia", currencyCode: "etb", currencySymbol: "ብር" },
//   { country: "Fiji", currencyCode: "fjd", currencySymbol: "$" },
//   { country: "Finland", currencyCode: "eur", currencySymbol: "€" },
//   { country: "France", currencyCode: "eur", currencySymbol: "€" },
//   { country: "Gabon", currencyCode: "xaf", currencySymbol: "FCFA" },
//   { country: "Gambia", currencyCode: "gmd", currencySymbol: "D" },
//   { country: "Georgia", currencyCode: "gel", currencySymbol: "₾" },
//   { country: "Germany", currencyCode: "eur", currencySymbol: "€" },
//   { country: "Ghana", currencyCode: "ghs", currencySymbol: "₵" },
//   { country: "Greece", currencyCode: "eur", currencySymbol: "€" },
//   { country: "Grenada", currencyCode: "xcd", currencySymbol: "$" },
//   { country: "Guatemala", currencyCode: "gtq", currencySymbol: "Q" },
//   { country: "Guinea", currencyCode: "gnf", currencySymbol: "FG" },
//   { country: "Guinea-Bissau", currencyCode: "xof", currencySymbol: "CFA" },
//   { country: "Guyana", currencyCode: "gyd", currencySymbol: "$" },
//   { country: "Haiti", currencyCode: "htg", currencySymbol: "G" },
//   { country: "Honduras", currencyCode: "hnl", currencySymbol: "L" },
//   { country: "Hungary", currencyCode: "huf", currencySymbol: "Ft" },
//   { country: "Iceland", currencyCode: "isk", currencySymbol: "kr" },
//   { country: "India", currencyCode: "inr", currencySymbol: "₹" },
//   { country: "Indonesia", currencyCode: "idr", currencySymbol: "Rp" },
//   { country: "Iran", currencyCode: "irr", currencySymbol: "﷼" },
//   { country: "Iraq", currencyCode: "iqd", currencySymbol: "ع.د" },
//   { country: "Ireland", currencyCode: "eur", currencySymbol: "€" },
//   { country: "Israel", currencyCode: "ils", currencySymbol: "₪" },
//   { country: "Italy", currencyCode: "eur", currencySymbol: "€" },
//   { country: "Jamaica", currencyCode: "jmd", currencySymbol: "J$" },
//   { country: "Japan", currencyCode: "jpy", currencySymbol: "¥" },
//   { country: "Jordan", currencyCode: "jod", currencySymbol: "د.ا" },
//   { country: "Kazakhstan", currencyCode: "kzt", currencySymbol: "₸" },
//   { country: "Kenya", currencyCode: "kes", currencySymbol: "KSh" },
//   { country: "Kiribati", currencyCode: "aud", currencySymbol: "$" },
//   { country: "Korea (South)", currencyCode: "krw", currencySymbol: "₩" },
//   { country: "Kuwait", currencyCode: "kwd", currencySymbol: "د.ك" },
//   { country: "Kyrgyzstan", currencyCode: "kgs", currencySymbol: "лв" },
//   { country: "Laos", currencyCode: "lak", currencySymbol: "₭" },
//   { country: "Latvia", currencyCode: "eur", currencySymbol: "€" },
//   { country: "Lebanon", currencyCode: "lbp", currencySymbol: "ل.ل" },
//   { country: "Lesotho", currencyCode: "lsl", currencySymbol: "L" },
//   { country: "Liberia", currencyCode: "lrd", currencySymbol: "$" },
//   { country: "Libya", currencyCode: "lyd", currencySymbol: "ل.د" },
//   { country: "Liechtenstein", currencyCode: "chf", currencySymbol: "CHF" },
//   { country: "Lithuania", currencyCode: "eur", currencySymbol: "€" },
//   { country: "Luxembourg", currencyCode: "eur", currencySymbol: "€" },
//   { country: "Madagascar", currencyCode: "mga", currencySymbol: "Ar" },
//   { country: "Malawi", currencyCode: "mwk", currencySymbol: "MK" },
//   { country: "Malaysia", currencyCode: "myr", currencySymbol: "RM" },
//   { country: "Maldives", currencyCode: "mvr", currencySymbol: "Rf" },
//   { country: "Mali", currencyCode: "xof", currencySymbol: "CFA" },
//   { country: "Malta", currencyCode: "eur", currencySymbol: "€" },
//   { country: "Marshall Islands", currencyCode: "usd", currencySymbol: "$" },
//   { country: "Mauritania", currencyCode: "mru", currencySymbol: "UM" },
//   { country: "Mauritius", currencyCode: "mur", currencySymbol: "₨" },
//   { country: "Mexico", currencyCode: "mxn", currencySymbol: "$" },
//   { country: "Micronesia", currencyCode: "usd", currencySymbol: "$" },
//   { country: "Moldova", currencyCode: "mdl", currencySymbol: "L" },
//   { country: "Monaco", currencyCode: "eur", currencySymbol: "€" },
//   { country: "Mongolia", currencyCode: "mnt", currencySymbol: "₮" },
//   { country: "Montenegro", currencyCode: "eur", currencySymbol: "€" },
//   { country: "Morocco", currencyCode: "mad", currencySymbol: "د.م." },
//   { country: "Mozambique", currencyCode: "mzn", currencySymbol: "MT" },
//   { country: "Myanmar", currencyCode: "mmk", currencySymbol: "K" },
//   { country: "Namibia", currencyCode: "nad", currencySymbol: "$" },
//   { country: "Nauru", currencyCode: "aud", currencySymbol: "$" },
//   { country: "Nepal", currencyCode: "npr", currencySymbol: "₨" },
//   { country: "Netherlands", currencyCode: "eur", currencySymbol: "€" },
//   { country: "New Zealand", currencyCode: "nzd", currencySymbol: "$" },
//   { country: "Nicaragua", currencyCode: "nio", currencySymbol: "C$" },
//   { country: "Niger", currencyCode: "xof", currencySymbol: "CFA" },
//   { country: "Nigeria", currencyCode: "ngn", currencySymbol: "₦" },
//   { country: "North Macedonia", currencyCode: "mkd", currencySymbol: "ден" },
//   { country: "Norway", currencyCode: "nok", currencySymbol: "kr" },
//   { country: "Oman", currencyCode: "omr", currencySymbol: "ر.ع." },
//   { country: "Pakistan", currencyCode: "pkr", currencySymbol: "₨" },
//   { country: "Palau", currencyCode: "usd", currencySymbol: "$" },
//   { country: "Panama", currencyCode: "pab", currencySymbol: "B/." },
//   { country: "Papua New Guinea", currencyCode: "pgk", currencySymbol: "K" },
//   { country: "Paraguay", currencyCode: "pyg", currencySymbol: "₲" },
//   { country: "Peru", currencyCode: "pen", currencySymbol: "S/." },
//   { country: "Philippines", currencyCode: "php", currencySymbol: "₱" },
//   { country: "Poland", currencyCode: "pln", currencySymbol: "zł" },
//   { country: "Portugal", currencyCode: "eur", currencySymbol: "€" },
//   { country: "Qatar", currencyCode: "qar", currencySymbol: "ر.ق" },
//   { country: "Romania", currencyCode: "ron", currencySymbol: "lei" },
//   { country: "Russia", currencyCode: "rub", currencySymbol: "₽" },
//   { country: "Rwanda", currencyCode: "rwf", currencySymbol: "Fr" },
//   { country: "Saint Kitts and Nevis", currencyCode: "xcd", currencySymbol: "$" },
//   { country: "Saint Lucia", currencyCode: "xcd", currencySymbol: "$" },
//   { country: "Saint Vincent and the Grenadines", currencyCode: "xcd", currencySymbol: "$" },
//   { country: "Samoa", currencyCode: "wst", currencySymbol: "T" },
//   { country: "San Marino", currencyCode: "eur", currencySymbol: "€" },
//   { country: "São Tomé and Príncipe", currencyCode: "stn", currencySymbol: "Db" },
//   { country: "Saudi Arabia", currencyCode: "sar", currencySymbol: "ر.س" },
//   { country: "Senegal", currencyCode: "xof", currencySymbol: "CFA" },
//   { country: "Serbia", currencyCode: "rsd", currencySymbol: "дин." },
//   { country: "Seychelles", currencyCode: "scr", currencySymbol: "₨" },
//   { country: "Sierra Leone", currencyCode: "sll", currencySymbol: "Le" },
//   { country: "Singapore", currencyCode: "sgd", currencySymbol: "$" },
//   { country: "Slovakia", currencyCode: "eur", currencySymbol: "€" },
//   { country: "Slovenia", currencyCode: "eur", currencySymbol: "€" },
//   { country: "Solomon Islands", currencyCode: "sbd", currencySymbol: "$" },
//   { country: "Somalia", currencyCode: "sos", currencySymbol: "Sh" },
//   { country: "South Africa", currencyCode: "zar", currencySymbol: "R" },
//   { country: "South Korea", currencyCode: "krw", currencySymbol: "₩" },
//   { country: "South Sudan", currencyCode: "ssp", currencySymbol: "£" },
//   { country: "Spain", currencyCode: "eur", currencySymbol: "€" },
//   { country: "Sri Lanka", currencyCode: "lkr", currencySymbol: "₨" },
//   { country: "Sudan", currencyCode: "sdg", currencySymbol: "£" },
//   { country: "Suriname", currencyCode: "srd", currencySymbol: "$" },
//   { country: "Sweden", currencyCode: "sek", currencySymbol: "kr" },
//   { country: "Switzerland", currencyCode: "chf", currencySymbol: "CHF" },
//   { country: "Syria", currencyCode: "syp", currencySymbol: "£" },
//   { country: "Taiwan", currencyCode: "twd", currencySymbol: "NT$" },
//   { country: "Tajikistan", currencyCode: "tjs", currencySymbol: "ЅМ" },
//   { country: "Tanzania", currencyCode: "tzs", currencySymbol: "Sh" },
//   { country: "Thailand", currencyCode: "thb", currencySymbol: "฿" },
//   { country: "Timor-Leste", currencyCode: "usd", currencySymbol: "$" },
//   { country: "Togo", currencyCode: "xof", currencySymbol: "CFA" },
//   { country: "Tonga", currencyCode: "top", currencySymbol: "T$" },
//   { country: "Trinidad and Tobago", currencyCode: "ttd", currencySymbol: "$" },
//   { country: "Tunisia", currencyCode: "tnd", currencySymbol: "د.ت" },
//   { country: "Turkey", currencyCode: "try", currencySymbol: "₺" },
//   { country: "Turkmenistan", currencyCode: "tmt", currencySymbol: "m" },
//   { country: "Tuvalu", currencyCode: "aud", currencySymbol: "$" },
//   { country: "Uganda", currencyCode: "ugx", currencySymbol: "Sh" },
//   { country: "Ukraine", currencyCode: "uah", currencySymbol: "₴" },
//   { country: "United Arab Emirates", currencyCode: "aed", currencySymbol: "د.إ" },
//   { country: "United Kingdom", currencyCode: "gbp", currencySymbol: "£" },
//   { country: "United States", currencyCode: "usd", currencySymbol: "$" },
//   { country: "Uruguay", currencyCode: "uyu", currencySymbol: "$" },
//   { country: "Uzbekistan", currencyCode: "uzs", currencySymbol: "so'm" },
//   { country: "Vanuatu", currencyCode: "vuv", currencySymbol: "Vt" },
//   { country: "Vatican City", currencyCode: "eur", currencySymbol: "€" },
//   { country: "Venezuela", currencyCode: "ves", currencySymbol: "Bs.S" },
//   { country: "Vietnam", currencyCode: "vnd", currencySymbol: "₫" },
//   { country: "Yemen", currencyCode: "yer", currencySymbol: "﷼" },
//   { country: "Zambia", currencyCode: "zmw", currencySymbol: "ZK" },
//   { country: "Zimbabwe", currencyCode: "zwl", currencySymbol: "$" },
// ];

export const CURRENCY_DATA = [
  { currencyCode: "aed", currencySymbol: "د.إ" },
  { currencyCode: "afn", currencySymbol: "؋" },
  { currencyCode: "all", currencySymbol: "Lek" },
  { currencyCode: "amd", currencySymbol: "֏" },
  { currencyCode: "ang", currencySymbol: "ƒ" },
  { currencyCode: "aoa", currencySymbol: "Kz" },
  { currencyCode: "ars", currencySymbol: "$" },
  { currencyCode: "aud", currencySymbol: "$" },
  { currencyCode: "awg", currencySymbol: "ƒ" },
  { currencyCode: "azn", currencySymbol: "₼" },
  { currencyCode: "bam", currencySymbol: "KM" },
  { currencyCode: "bbd", currencySymbol: "$" },
  { currencyCode: "bdt", currencySymbol: "৳" },
  { currencyCode: "bgn", currencySymbol: "лв" },
  { currencyCode: "bif", currencySymbol: "Fr" },
  { currencyCode: "bmd", currencySymbol: "$" },
  { currencyCode: "bnd", currencySymbol: "$" },
  { currencyCode: "bob", currencySymbol: "$b" },
  { currencyCode: "brl", currencySymbol: "R$" },
  { currencyCode: "bsd", currencySymbol: "$" },
  { currencyCode: "bwp", currencySymbol: "P" },
  { currencyCode: "bzd", currencySymbol: "$" },
  { currencyCode: "cad", currencySymbol: "$" },
  { currencyCode: "cdf", currencySymbol: "Fr" },
  { currencyCode: "chf", currencySymbol: "CHF" },
  { currencyCode: "clp", currencySymbol: "$" },
  { currencyCode: "cny", currencySymbol: "¥" },
  { currencyCode: "cop", currencySymbol: "$" },
  { currencyCode: "crc", currencySymbol: "₡" },
  { currencyCode: "cve", currencySymbol: "$" },
  { currencyCode: "czk", currencySymbol: "Kč" },
  { currencyCode: "djf", currencySymbol: "Fr" },
  { currencyCode: "dkk", currencySymbol: "kr" },
  { currencyCode: "dop", currencySymbol: "$" },
  { currencyCode: "dzd", currencySymbol: "د.ج" },
  { currencyCode: "egp", currencySymbol: "ج.م" },
  { currencyCode: "etb", currencySymbol: "Br" },
  { currencyCode: "eur", currencySymbol: "€" },
  { currencyCode: "fjd", currencySymbol: "$" },
  { currencyCode: "fkp", currencySymbol: "£" },
  { currencyCode: "gbp", currencySymbol: "£" },
  { currencyCode: "gel", currencySymbol: "₾" },
  { currencyCode: "gip", currencySymbol: "£" },
  { currencyCode: "gmd", currencySymbol: "D" },
  { currencyCode: "gnf", currencySymbol: "Fr" },
  { currencyCode: "gtq", currencySymbol: "Q" },
  { currencyCode: "gyd", currencySymbol: "$" },
  { currencyCode: "hkd", currencySymbol: "$" },
  { currencyCode: "hnl", currencySymbol: "L" },
  { currencyCode: "hrk", currencySymbol: "kn" },
  { currencyCode: "htg", currencySymbol: "G" },
  { currencyCode: "huf", currencySymbol: "Ft" },
  { currencyCode: "idr", currencySymbol: "Rp" },
  { currencyCode: "ils", currencySymbol: "₪" },
  { currencyCode: "inr", currencySymbol: "₹" },
  { currencyCode: "isk", currencySymbol: "kr" },
  { currencyCode: "jmd", currencySymbol: "$" },
  { currencyCode: "jpy", currencySymbol: "¥" },
  { currencyCode: "kes", currencySymbol: "Sh" },
  { currencyCode: "kgs", currencySymbol: "с" },
  { currencyCode: "khr", currencySymbol: "៛" },
  { currencyCode: "kmf", currencySymbol: "Fr" },
  { currencyCode: "krw", currencySymbol: "₩" },
  { currencyCode: "kyd", currencySymbol: "$" },
  { currencyCode: "kzt", currencySymbol: "₸" },
  { currencyCode: "lak", currencySymbol: "₭" },
  { currencyCode: "lbp", currencySymbol: "ل.ل" },
  { currencyCode: "lkr", currencySymbol: "₨" },
  { currencyCode: "lrd", currencySymbol: "$" },
  { currencyCode: "lsl", currencySymbol: "M" },
  { currencyCode: "mad", currencySymbol: "د.م." },
  { currencyCode: "mdl", currencySymbol: "lei" },
  { currencyCode: "mga", currencySymbol: "Ar" },
  { currencyCode: "mkd", currencySymbol: "ден" },
  { currencyCode: "mmk", currencySymbol: "Ks" },
  { currencyCode: "mnt", currencySymbol: "₮" },
  { currencyCode: "mop", currencySymbol: "MOP$" },
  { currencyCode: "mro", currencySymbol: "UM" },
  { currencyCode: "mur", currencySymbol: "₨" },
  { currencyCode: "mvr", currencySymbol: "Rf" },
  { currencyCode: "mwk", currencySymbol: "MK" },
  { currencyCode: "mxn", currencySymbol: "$" },
  { currencyCode: "myr", currencySymbol: "RM" },
  { currencyCode: "mzn", currencySymbol: "MT" },
  { currencyCode: "nad", currencySymbol: "$" },
  { currencyCode: "ngn", currencySymbol: "₦" },
  { currencyCode: "nio", currencySymbol: "C$" },
  { currencyCode: "nok", currencySymbol: "kr" },
  { currencyCode: "npr", currencySymbol: "₨" },
  { currencyCode: "nzd", currencySymbol: "$" },
  { currencyCode: "pab", currencySymbol: "B/." },
  { currencyCode: "pen", currencySymbol: "S/." },
  { currencyCode: "pgk", currencySymbol: "K" },
  { currencyCode: "php", currencySymbol: "₱" },
  { currencyCode: "pkr", currencySymbol: "₨" },
  { currencyCode: "pln", currencySymbol: "zł" },
  { currencyCode: "pyg", currencySymbol: "₲" },
  { currencyCode: "qar", currencySymbol: "ر.ق" },
  { currencyCode: "ron", currencySymbol: "lei" },
  { currencyCode: "rsd", currencySymbol: "дин." },
  { currencyCode: "rub", currencySymbol: "₽" },
  { currencyCode: "rwf", currencySymbol: "Fr" },
  { currencyCode: "sar", currencySymbol: "ر.س" },
  { currencyCode: "sbd", currencySymbol: "$" },
  { currencyCode: "scr", currencySymbol: "₨" },
  { currencyCode: "sek", currencySymbol: "kr" },
  { currencyCode: "sgd", currencySymbol: "$" },
  { currencyCode: "shp", currencySymbol: "£" },
  { currencyCode: "sll", currencySymbol: "Le" },
  { currencyCode: "sos", currencySymbol: "Sh" },
  { currencyCode: "srd", currencySymbol: "$" },
  { currencyCode: "std", currencySymbol: "Db" },
  { currencyCode: "szl", currencySymbol: "L" },
  { currencyCode: "thb", currencySymbol: "฿" },
  { currencyCode: "tjs", currencySymbol: "ЅМ" },
  { currencyCode: "top", currencySymbol: "T$" },
  { currencyCode: "try", currencySymbol: "₺" },
  { currencyCode: "ttd", currencySymbol: "$" },
  { currencyCode: "twd", currencySymbol: "NT$" },
  { currencyCode: "tzs", currencySymbol: "Sh" },
  { currencyCode: "uah", currencySymbol: "₴" },
  { currencyCode: "ugx", currencySymbol: "Sh" },
  { currencyCode: "usd", currencySymbol: "$" },
  { currencyCode: "uyu", currencySymbol: "$" },
  { currencyCode: "uzs", currencySymbol: "so'm" },
  { currencyCode: "vnd", currencySymbol: "₫" },
  { currencyCode: "vuv", currencySymbol: "Vt" },
  { currencyCode: "wst", currencySymbol: "T" },
  { currencyCode: "xaf", currencySymbol: "Fr" },
  { currencyCode: "xcd", currencySymbol: "$" },
  { currencyCode: "xof", currencySymbol: "Fr" },
  { currencyCode: "xpf", currencySymbol: "Fr" },
  { currencyCode: "yer", currencySymbol: "﷼" },
  { currencyCode: "zar", currencySymbol: "R" },
  { currencyCode: "zmw", currencySymbol: "ZK" },
];

export const CATEGORY_ORDER = [
  "Water Toys",
  "Catering",
  "Cleaning",
  "Refueling",
  "Other Services",
  "Captain",
];

export const ADDON_CATEGORIES = [
  {
    label: "Water Toys",
    value: "WATER_TOYS",
  },
  {
    label: "Cleaning",
    value: "CLEANING",
  },
  {
    label: "Catering",
    value: "CATERING",
  },
  {
    label: "Captain",
    value: "CAPTAIN",
  },
  {
    label: "Refueling",
    value: "REFUELING",
  },
  {
    label: "Other Services",
    value: "OTHER_SERVICES",
  },
];

export const ADDON_PRODUCT_TYPES = [
  { label: "Service", value: "Service" },
  // { label: "Membership", value: "Membership" },
  { label: "Fuel", value: "Fuel" },
  { label: "Electricity", value: "Electricity" },
  { label: "ExtraDays", value: "ExtraDays" },
  { label: "EngineHours", value: "EngineHours" },
];

export const BOAT_CONDITIONS = [
  { label: "PERFECT", value: "PERFECT" },
  { label: "GOOD", value: "GOOD" },
  { label: "OK", value: "OK" },
  { label: "NO", value: "NO" },
];

export const SEVERITY_COLORS = {
  HIGH: "#ff8888",
  MEDIUM: "#ffe066",
  LOW: "#72e07d",
};

export const ALL_ISSUES = {
  mooringLines: "Mooring Lines",
  fenders: "Fenders",
  covers: "Covers",
  lowFreshWater: "Low Fresh Water",
  dirtiness: "Dirtiness",
  lowFuelVoltage: "Low Fuel Voltage",
  propellers: "Propellers",
  somethingBroken: "Something Broken",
  coolerHvacSystem: "Cooler Hvac System",
  other: "Other",
};

export const CATEGORY_FILTERS = {
  WATER_TOYS: "Water Toys",
  CATERING: "Catering",
  CLEANING: "Cleaning",
  OTHER_SERVICES: "Other Services",
  REFUELING: "Refueling",
  CAPTAIN: "Captain",
};

export const CENTER_TABS = {
  CENTER: "Center",
  BOAT_FLEETS: "Boat Fleets",
  SERVICES: "Services",
  MEMBERSHIPS: "Memberships",
};

export const BOOKING_TABS = {
  ISSUE_LIST: "Issue List",
  CHECKIN_CHECKOUT: "Check In / Check Out",
};
export const CLUB_TYPE = [
  "Boat Club",
  "Charter company",
  "Harbour",
  "Boat Manufacturer",
  "Other",
];

export const ALL_SERVICE_NAMES = {
  CATERING: "Catering",
  CAPTAIN: "Captain",
  CLEANING: "Cleaning",
  OTHER_SERVICES: "Other Services",
  EXTRA_ORDER: "Extra Order",
  REFUELING: "Refueling",
  WATER_TOYS: "Water Toys",
};
export const STATUS = {
  ACTIVE: "Active",
  INACTIVE: "Inactive",
  TRUE: "true",
  FALSE: "false",
};

export const MEMBERSHIP_TYPE = {
  GOLD: "Gold",
  SILVER: "Silver",
  PLATINUM: "Platinum",
};

export const S3_FOLDER_NAME = {
  DOCUMENTS: "doc",
  CO_MEMBERS_DOCUMENTS: "comembers_docs",
  PROFILE_IMAGES: "user",
  CHECKIN_CHECKOUT_REPORT_ISSUE: "issues",
  HARBORS_IMAGES: "harbors",
  BOAT_IMAGES: "boats",
  CLUB_LOGO: "club",
  POSTS: "posts",
};

export const USER_ROLE = {
  MOXSEA_Administrator: {
    value: "MOXSEA_Administrator",
    label: "MOXSEA Administrator",
  },
  MOXSEA_Support: {
    value: "MOXSEA_Support",
    label: "MOXSEA Support",
  },
  Clubmanager: {
    value: "Club_Manager",
    label: "Club Manager",
  },
  Clubworker: {
    value: "Club_Worker",
    label: "Club Worker",
  },
};

export const CountryList = [
  "Andorra",
  "Argentina",
  "Australia",
  "Austria",
  "Bahrain",
  "Belarus",
  "Belgium",
  "Bolivia",
  "Brazil",
  "Bulgaria",
  "Canada",
  "Chile",
  "China",
  "Colombia",
  "Costa Rica",
  "Croatia",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Estonia",
  "EU",
  "Finland",
  "France",
  "Georgia",
  "Germany",
  "Greece",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Ireland",
  "Israel",
  "Italy",
  "Japan",
  "Kazakhstan",
  "Kenya",
  "Latvia",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Malaysia",
  "Malta",
  "Mexico",
  "Moldova",
  "Morocco",
  "Netherlands",
  "New Zealand",
  "Nigeria",
  "Norway",
  "Oman",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Romania",
  "Russia",
  "Saudi Arabia",
  "Serbia",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "South Africa",
  "South Korea",
  "Spain",
  "Sweden",
  "Switzerland",
  "Taiwan",
  "Tanzania",
  "Thailand",
  "Turkey",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
  "Uruguay",
  "Uzbekistan",
  "Venezuela",
  "Vietnam",
];

// export const CountryList = [
//   "Andorra, Principality Of",
//   "United Arab Emirates",
//   "Afghanistan, Islamic State Of",
//   "Antigua And Barbuda",
//   "Anguilla",
//   "Albania",
//   "Armenia",
//   "Netherlands Antilles",
//   "Angola",
//   "Antarctica",
//   "Argentina",
//   "American Samoa",
//   "Austria",
//   "Australia",
//   "Aruba",
//   "Azerbaidjan",
//   "Bosnia-Herzegovina",
//   "Barbados",
//   "Bangladesh",
//   "Belgium",
//   "Burkina Faso",
//   "Bulgaria",
//   "Bahrain",
//   "Burundi",
//   "Benin",
//   "Bermuda",
//   "Brunei Darussalam",
//   "Bolivia",
//   "Brazil",
//   "Bahamas",
//   "Bhutan",
//   "Bouvet Island",
//   "Botswana",
//   "Belarus",
//   "Belize",
//   "Canada",
//   "Cocos (Keeling) Islands",
//   "Central African Republic",
//   "Congo, The Democratic Republic Of The",
//   "Congo",
//   "Switzerland",
//   "Ivory Coast (Cote D'Ivoire)",
//   "Cook Islands",
//   "Chile",
//   "Cameroon",
//   "China",
//   "Colombia",
//   "Costa Rica",
//   "Former Czechoslovakia",
//   "Cuba",
//   "Cape Verde",
//   "Christmas Island",
//   "Cyprus",
//   "Czech Republic",
//   "Germany",
//   "Djibouti",
//   "Denmark",
//   "Dominica",
//   "Dominican Republic",
//   "Algeria",
//   "Ecuador",
//   "Estonia",
//   "Egypt",
//   "Western Sahara",
//   "Eritrea",
//   "Spain",
//   "Ethiopia",
//   "Finland",
//   "Fiji",
//   "Falkland Islands",
//   "Micronesia",
//   "Faroe Islands",
//   "France",
//   "France (European Territory)",
//   "Gabon",
//   "Great Britain",
//   "Grenada",
//   "Georgia",
//   "French Guyana",
//   "Ghana",
//   "Gibraltar",
//   "Greenland",
//   "Gambia",
//   "Guinea",
//   "Guadeloupe (French)",
//   "Equatorial Guinea",
//   "Greece",
//   "S. Georgia & S. Sandwich Isls.",
//   "Guatemala",
//   "Guam (USA)",
//   "Guinea Bissau",
//   "Guyana",
//   "Hong Kong",
//   "Heard And McDonald Islands",
//   "Honduras",
//   "Croatia",
//   "Haiti",
//   "Hungary",
//   "Indonesia",
//   "Ireland",
//   "Israel",
//   "India",
//   "British Indian Ocean Territory",
//   "Iraq",
//   "Iran",
//   "Iceland",
//   "Italy",
//   "Jamaica",
//   "Jordan",
//   "Japan",
//   "Kenya",
//   "Kyrgyz Republic (Kyrgyzstan)",
//   "Cambodia, Kingdom Of",
//   "Kiribati",
//   "Comoros",
//   "Saint Kitts & Nevis Anguilla",
//   "North Korea",
//   "South Korea",
//   "Kuwait",
//   "Cayman Islands",
//   "Kazakhstan",
//   "Laos",
//   "Lebanon",
//   "Saint Lucia",
//   "Liechtenstein",
//   "Sri Lanka",
//   "Liberia",
//   "Lesotho",
//   "Lithuania",
//   "Luxembourg",
//   "Latvia",
//   "Libya",
//   "Morocco",
//   "Monaco",
//   "Moldavia",
//   "Madagascar",
//   "Marshall Islands",
//   "Macedonia",
//   "Mali",
//   "Myanmar",
//   "Mongolia",
//   "Macau",
//   "Northern Mariana Islands",
//   "Martinique (French)",
//   "Mauritania",
//   "Montserrat",
//   "Malta",
//   "Mauritius",
//   "Maldives",
//   "Malawi",
//   "Mexico",
//   "Malaysia",
//   "Mozambique",
//   "Namibia",
//   "New Caledonia (French)",
//   "Niger",
//   "Norfolk Island",
//   "Nigeria",
//   "Nicaragua",
//   "Netherlands",
//   "Norway",
//   "Nepal",
//   "Nauru",
//   "Neutral Zone",
//   "Niue",
//   "New Zealand",
//   "Oman",
//   "Panama",
//   "Peru",
//   "Polynesia (French)",
//   "Papua New Guinea",
//   "Philippines",
//   "Pakistan",
//   "Poland",
//   "Saint Pierre And Miquelon",
//   "Pitcairn Island",
//   "Puerto Rico",
//   "Portugal",
//   "Palau",
//   "Paraguay",
//   "Qatar",
//   "Reunion (French)",
//   "Romania",
//   "Russian Federation",
//   "Rwanda",
//   "Saudi Arabia",
//   "Solomon Islands",
//   "Seychelles",
//   "Sudan",
//   "Sweden",
//   "Singapore",
//   "Saint Helena",
//   "Slovenia",
//   "Svalbard And Jan Mayen Islands",
//   "Slovak Republic",
//   "Sierra Leone",
//   "San Marino",
//   "Senegal",
//   "Somalia",
//   "Suriname",
//   "Saint Tome (Sao Tome) And Principe",
//   "Former USSR",
//   "El Salvador",
//   "Syria",
//   "Swaziland",
//   "Turks And Caicos Islands",
//   "Chad",
//   "French Southern Territories",
//   "Togo",
//   "Thailand",
//   "Tadjikistan",
//   "Tokelau",
//   "Turkmenistan",
//   "Tunisia",
//   "Tonga",
//   "East Timor",
//   "Turkey",
//   "Trinidad And Tobago",
//   "Tuvalu",
//   "Taiwan",
//   "Tanzania",
//   "Ukraine",
//   "Uganda",
//   "United Kingdom",
//   "USA Minor Outlying Islands",
//   "United States",
//   "Uruguay",
//   "Uzbekistan",
//   "Holy See (Vatican City State)",
//   "Saint Vincent & Grenadines",
//   "Venezuela",
//   "Virgin Islands (British)",
//   "Virgin Islands (USA)",
//   "Vietnam",
//   "Vanuatu",
//   "Wallis And Futuna Islands",
//   "Samoa",
//   "Yemen",
//   "Mayotte",
//   "Yugoslavia",
//   "South Africa",
//   "Zambia",
//   "Zaire",
//   "Zimbabwe",
// ];

export const Currencies = [
  { id: 1, name: "USD" },
  { id: 2, name: "EUR" },
  { id: 3, name: "JPY" },
  { id: 4, name: "GBP" },
  { id: 5, name: "AUD" },
  { id: 6, name: "CAD" },
  { id: 7, name: "CHF" },
  { id: 8, name: "CNY" },
  { id: 9, name: "SEK" },
  { id: 10, name: "NZD" },
  { id: 11, name: "SGD" },
  { id: 12, name: "HKD" },
  { id: 13, name: "NOK" },
  { id: 14, name: "KRW" },
  { id: 15, name: "MXN" },
  { id: 16, name: "INR" },
  { id: 17, name: "BRL" },
  { id: 18, name: "ZAR" },
  { id: 19, name: "RUB" },
  { id: 20, name: "SAR" },
];

export const Hours = Array.from({ length: 24 }, (_, index) =>
  (index + 1).toString()
);

export const Vat = Array.from({ length: 100 }, (_, index) =>
  (index + 1).toString()
);

export const Timezones_List = [
  {
    id: "Dateline Standard Time",
    value: "(UTC-12:00) International Date Line West",
    javaZoneId: "Etc/GMT+12",
  },
  {
    id: "UTC-11",
    value: "(UTC-11:00) Coordinated Universal Time-11",
    javaZoneId: "Etc/GMT+11",
  },
  {
    id: "Hawaiian Standard Time",
    value: "(UTC-10:00) Hawaii",
    javaZoneId: "Pacific/Honolulu",
  },
  {
    id: "Alaskan Standard Time",
    value: "(UTC-09:00) Alaska",
    javaZoneId: "America/Anchorage",
  },
  {
    id: "Pacific Standard Time",
    value: "(UTC-08:00) Pacific Time (US & Canada)",
    javaZoneId: "America/Los_Angeles",
  },
  {
    id: "Mountain Standard Time",
    value: "(UTC-07:00) Mountain Time (US & Canada)",
    javaZoneId: "America/Denver",
  },
  {
    id: "Central Standard Time",
    value: "(UTC-06:00) Central Time (US & Canada)",
    javaZoneId: "America/Chicago",
  },
  {
    id: "Eastern Standard Time",
    value: "(UTC-05:00) Eastern Time (US & Canada)",
    javaZoneId: "America/New_York",
  },
  {
    id: "Atlantic Standard Time",
    value: "(UTC-04:00) Atlantic Time (Canada)",
    javaZoneId: "America/Halifax",
  },
  {
    id: "Greenland Standard Time",
    value: "(UTC-03:00) Greenland",
    javaZoneId: "America/Godthab",
  },
  {
    id: "Argentina Standard Time",
    value: "(UTC-03:00) Argentina",
    javaZoneId: "America/Argentina/Buenos_Aires",
  },
  {
    id: "Azores Standard Time",
    value: "(UTC-01:00) Azores",
    javaZoneId: "Atlantic/Azores",
  },
  {
    id: "GMT Standard Time",
    value: "(UTC+00:00) Greenwich Mean Time: Dublin, Edinburgh, Lisbon, London",
    javaZoneId: "Europe/London",
  },
  {
    id: "Central European Standard Time",
    value: "(UTC+01:00) Central European Time",
    javaZoneId: "Europe/Berlin",
  },
  {
    id: "South Africa Standard Time",
    value: "(UTC+02:00) Harare, Pretoria",
    javaZoneId: "Africa/Johannesburg",
  },
  {
    id: "Russian Standard Time",
    value: "(UTC+03:00) Moscow, St. Petersburg, Volgograd",
    javaZoneId: "Europe/Moscow",
  },
  {
    id: "Arabian Standard Time",
    value: "(UTC+04:00) Abu Dhabi, Muscat",
    javaZoneId: "Asia/Dubai",
  },
  {
    id: "Pakistan Standard Time",
    value: "(UTC+05:00) Islamabad, Karachi",
    javaZoneId: "Asia/Karachi",
  },
  {
    id: "India Standard Time",
    value: "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi",
    javaZoneId: "Asia/Kolkata",
  },
  {
    id: "Bangkok Standard Time",
    value: "(UTC+07:00) Bangkok, Hanoi, Jakarta",
    javaZoneId: "Asia/Bangkok",
  },
  {
    id: "China Standard Time",
    value: "(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi",
    javaZoneId: "Asia/Shanghai",
  },
  {
    id: "Tokyo Standard Time",
    value: "(UTC+09:00) Osaka, Sapporo, Tokyo",
    javaZoneId: "Asia/Tokyo",
  },
  {
    id: "AUS Eastern Standard Time",
    value: "(UTC+10:00) Canberra, Melbourne, Sydney",
    javaZoneId: "Australia/Sydney",
  },
  {
    id: "New Zealand Standard Time",
    value: "(UTC+12:00) Auckland, Wellington",
    javaZoneId: "Pacific/Auckland",
  },
  {
    id: "Tonga Standard Time",
    value: "(UTC+13:00) Nuku'alofa",
    javaZoneId: "Pacific/Tongatapu",
  },
];

export const REACT_TABLE_STYLE_PROP = {
  borderRadius: 12,
};

const GeneralConst = () => {
  return;
};

export default GeneralConst;
