import { createContext, useContext, useState, useEffect } from "react";
import { API_URLS } from "shared/Constants/api.const";
import http from "shared/utils/http";
import { S3_FOLDER_NAME } from "shared/Constants/general.const";
import { useClubsContext } from "shared/ContextProviders/ClubsContextProvider/ClubsContextProvider";

const ClubSettingContext = createContext({
  clubDetails: [],
  editClubDetails: () => {},
  uploadImage: () => {},
  loading: false,
  setLoading: () => {},
  fetchClubDetails: () => {},
});

export const ClubSettingContextProvider = ({ children }) => {
  const { selectedClub } = useClubsContext();
  const [clubDetails, setClubDetails] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchClubDetails = async (clubId) => {
    const response = await http
      .get(`${API_URLS.CLUB_DETAILS.replace(":club-id", clubId)}`)
      .then((data) => data)
      .catch((error) => {
        console.error(error);
      });
    setClubDetails(response?.clubs || []);
    return response;
  };

  const editClubDetails = async (newClubDetails, clubId) => {
    await http.put(
      `${API_URLS.EDIT_CLUB_DETAILS.replace(":clubId", clubId)}`,
      newClubDetails
    );
  };

  const uploadImage = async (imageFile) => {
    const folderName = S3_FOLDER_NAME.CLUB_LOGO;
    const formData = new FormData();
    formData.append("files", imageFile);
    let res;

    res = await http.postFormData(
      `${API_URLS.UPLOAD_IMAGE}${folderName}`,
      formData
    );

    return res;
  };

  useEffect(() => {
    fetchClubDetails(selectedClub?.clubId);
  }, []);

  return (
    <ClubSettingContext.Provider
      value={{
        clubDetails,
        editClubDetails,
        uploadImage,
        loading,
        setLoading,
        fetchClubDetails,
      }}
    >
      {children}
    </ClubSettingContext.Provider>
  );
};

export const useClubSettingContext = () => useContext(ClubSettingContext);
