import {
  Box,
  Button,
  Flex,
  Image,
  Loader,
  Select,
  Text,
  TextInput,
  Tooltip,
} from "@mantine/core";
import { IconInfoCircle } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { FileToDataUrl } from "shared/utils/file";
import placeholder from "../../../../../../../assets/images/placeholder.png";
import { isEmail, isNotEmpty, useForm } from "@mantine/form";
import { useClubManagementContext } from "../../ClubManagement.context";
import toast from "react-hot-toast";
import { CountryList, VAT_TYPE } from "shared/Constants/general.const";
import { PhoneInput } from "react-international-phone";
import ImageUploader from "Components/Atoms/ImageUploader";

const AddClubs = ({ close }) => {
  const { addClub, uploadImage, addClubLoading, setAddClubLoading } =
    useClubManagementContext();
  const [fileUrl, setFileUrl] = useState("");
  const [file, setFile] = useState(null);
  const [phoneErrorFlag, setPhoneErrorFlag] = useState(false);

  const form = useForm({
    initialValues: {
      clubName: "",
      clubLogo: "",
      companyName: "",
      clubFirstName: "",
      clubLastName: "",
      clubStreetAddress: "",
      clubZipCode: "",
      clubCity: "",
      clubCountry: "",
      clubPhone: "",
      clubEmail: "",
      clubVat: "",
      commission: "5",
      clubVatType: "",
    },
    validate: {
      clubName: isNotEmpty("Please enter club name"),
      //   clubLogo: isNotEmpty( "Please upload club logo"),
      clubFirstName: isNotEmpty("Please enter club first name"),
      clubLastName: isNotEmpty("Please enter club last name"),
      companyName: isNotEmpty("Please enter company name"),
      clubStreetAddress: isNotEmpty("Please enter club street address"),
      clubZipCode: isNotEmpty("Please enter club zip code"),
      clubCity: isNotEmpty("Please enter club city"),
      clubCountry: isNotEmpty("Please enter club country"),
      // clubPhone: isNotEmpty("Please enter club phone"),
      clubEmail: isEmail("Please enter club email"),
      clubVat: isNotEmpty("Please enter club vat"),
      commission: isNotEmpty("Please enter commission"),
      clubVatType: isNotEmpty("Please select vat type"),
    },
  });
  useEffect(() => {
    if (file) {
      FileToDataUrl(file).then((url) => {
        setFileUrl(url);
      });
    }
  }, [file]);

  const handleSave = async () => {
    setAddClubLoading(true);
    const postData = {
      clubName: form.values.clubName,
      clubLogo: form.values.clubLogo,
      clubManagerId: null,
      companyName: form.values.companyName,
      clubContactFirstName: form.values.clubFirstName,
      clubContactLastName: form.values.clubLastName,
      clubStreetAddress: form.values.clubStreetAddress,
      clubPostcode: form.values.clubZipCode,
      clubCity: form.values.clubCity,
      clubCountry: form.values.clubCountry,
      clubPhone: form.values.clubPhone,
      clubEmail: form.values.clubEmail,
      clubVAT: form.values.clubVat,
      vatType: form.values.clubVatType,
      clubMoxseaCommissionPercent: Number(form.values.commission),
    };

    if (file) {
      await uploadImage(file)
        .then(async (res) => {
          const logo = res?.uploadedFileUrls[0];
          postData.clubLogo = logo;
          await addClub(postData)
            .then(() => {
              toast.success("Club created successfully!");
              close();
              setAddClubLoading(false);
            })
            .catch((error) => {
              toast.error(error.message || "Save failed. Please try again.");
              setAddClubLoading(false);
            });
        })
        .catch((error) => {
          toast.error(error.message || "Save failed. Please try again.");
          setAddClubLoading(false);
        });
    } else {
      await addClub(postData)
        .then(() => {
          toast.success("Club created successfully!");
          close();
          setAddClubLoading(false);
        })
        .catch((error) => {
          toast.error(error.message || "Save failed. Please try again.");
          setAddClubLoading(false);
        });
    }
  };

  const [vatType, setVatType] = useState([]);
  // const [vatRefNumber, setVatRefNumber] = useState(form.values.clubVat || "");

  useEffect(() => {
    if (!form.values.clubCountry) return;

    const filterVatType = VAT_TYPE.filter(
      (item) => item.countryName === form.values.clubCountry
    );
    setVatType(filterVatType[0]?.item);
    form.setFieldValue("clubVatType", null);
  }, [form.values.clubCountry]);

  // console.log("vatType", form.values.clubPhone.length < 4);
  const vatRefNumber = vatType?.find(
    (item) => item.vatType === form.values.clubVatType
  );

  return (
    <>
      {addClubLoading ? (
        <Flex justify={"center"} align={"center"} h={"70vh"}>
          <Loader />
        </Flex>
      ) : (
        <Flex direction="column" gap="md">
          <Box
            component="form"
            onSubmit={form.onSubmit((values) => {
              if (form.isValid()) {
                handleSave();
              } else {
              }
            })}
          >
            <Flex direction="column" rowGap={12}>
              <TextInput
                withAsterisk
                label="Club Name"
                placeholder="Club Name"
                {...form.getInputProps("clubName")}
              />
              <TextInput
                withAsterisk
                label="Company Name"
                placeholder="Company Name"
                {...form.getInputProps("companyName")}
              />
              <Flex direction="column" justify="center" mt={10}>
                <Text size="sm" fw="500">
                  Club Logo <span style={{ color: "#fb7852" }}>*</span>
                </Text>
                <Flex w={"100%"} justify={"center"}>
                  <Flex justify="center" w={180} h={120}>
                    <Image
                      fit="contain"
                      src={fileUrl || placeholder}
                      style={{ border: "1px solid #cdcaca" }}
                    />
                  </Flex>
                </Flex>
              </Flex>
              <ImageUploader
                setFileUrl={setFileUrl}
                fileUrl={fileUrl}
                setFile={setFile}
                file={file}
              />

              <TextInput
                label="Club Contact First Name"
                placeholder="Club Contact First Name"
                withAsterisk
                {...form.getInputProps("clubFirstName")}
              />
              <TextInput
                label="Club Contact Last Name"
                placeholder="Club Contact Last Name"
                withAsterisk
                {...form.getInputProps("clubLastName")}
              />
              <Flex direction="column">
                <Text size="sm" mb={4} fw={500}>
                  Phone
                  <span style={{ color: "red" }}> *</span>
                </Text>
                <PhoneInput
                  defaultCountry="de"
                  inputStyle={{
                    borderTopRightRadius: "24px",
                    borderBottomRightRadius: "24px",
                    width: "100%",
                  }}
                  {...form.getInputProps("clubPhone")}
                />
                {/* {phoneErrorFlag && (
                  <Text size="xs" c="red">
                    Please enter club phone
                  </Text>
                )} */}
              </Flex>
              <TextInput
                label="Club Email"
                withAsterisk
                placeholder="Club Email"
                {...form.getInputProps("clubEmail")}
              />
              <TextInput
                label="Club Street Address"
                placeholder="Club Street Address"
                withAsterisk
                {...form.getInputProps("clubStreetAddress")}
              />
              <TextInput
                label="Club PostCode"
                withAsterisk
                placeholder="Club PostCode"
                {...form.getInputProps("clubZipCode")}
              />
              <TextInput
                label="Club City"
                withAsterisk
                placeholder="Club City"
                {...form.getInputProps("clubCity")}
              />
              <Select
                label="Club Country"
                withAsterisk
                placeholder="Club Country"
                clearable
                searchable
                data={CountryList}
                {...form.getInputProps("clubCountry")}
              />
              <Select
                label="VAT Type"
                withAsterisk
                placeholder="VAT Type"
                disabled={!form.values.clubCountry}
                data={vatType?.map((id) => ({
                  label: id.vatType,
                  value: id.vatType,
                }))}
                {...form.getInputProps("clubVatType")}
              />
              <Flex w="100%" columnGap={18} align="flex-end">
                <TextInput
                  w={"100%"}
                  label={
                    <Text size="sm" fw={500}>
                      Club VAT Number{" "}
                      <Text span style={{ color: "#fb7852" }} mr={4}>
                        *
                      </Text>
                      <Tooltip
                        label={
                          vatRefNumber?.refNumber
                            ? `Example: ${vatRefNumber?.refNumber}`
                            : "Please select a VAT type"
                        }
                      >
                        <IconInfoCircle color="#AAAAAA" />
                      </Tooltip>
                    </Text>
                  }
                  // placeholder="Club VAT Number"
                  placeholder={
                    vatRefNumber?.refNumber
                      ? `Example: ${vatRefNumber?.refNumber}`
                      : "Club VAT Number"
                  }
                  {...form.getInputProps("clubVat")}
                />
              </Flex>
              <Select
                label="Clubs MOXSEA commission %"
                data={Array.from({ length: 101 }, (_, i) => `${i}`)}
                withAsterisk
                placeholder="Clubs MOXSEA commission %"
                {...form.getInputProps("commission")}
              />
            </Flex>
            <Flex justify="space-between" mt={12}>
              <Button onClick={close}>Cancel</Button>
              <Button type="submit">Save</Button>
            </Flex>
          </Box>
        </Flex>
      )}
    </>
  );
};

export default AddClubs;
